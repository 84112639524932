

import './pageFooter.scss';

const PageFooter = () => {
  return (
    <div className="footer">
      <div className="footer__divider"></div>
      <h2>Scandiweb Test assigment</h2>
    </div>
  );
};

export default PageFooter;